import React from "react"
import CategoryCard from "components/categoryCard"
import Layout from "components/layout"
import { graphql } from "gatsby"

const category = "gpu";
const path = "/category/gpu/";

export default function Category(props) {
  // collect images of pcparts category
  const pages = props.data.site.siteMetadata.pages.filter(page => page.category === category);
  pages.forEach(page => {
    page.image = page.image || "cover/defaultthumb.png"
    const name = page.image.split('.')[0].replace(/-/g,'');
    page.name = name.substring(name.lastIndexOf('/') + 1);
  });
  return (
    <Layout {...props} path={path}>
      <h1>GPU性能比較の記事一覧</h1>
      {pages.map(page => <CategoryCard {...props} {...page} />)}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        pages {
          title
          description
          image
          category
          path
          noIndex
        }
      }
    },
    grabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    geforce: file(relativePath: { eq: "cover/gpu/geforce.png"}) { ...eyecatchImg },
    geforcevsradeon: file(relativePath: { eq: "cover/gpu/geforce-vs-radeon.png"}) { ...eyecatchImg },
    radeon: file(relativePath: { eq: "cover/gpu/radeon.png"}) { ...eyecatchImg },
    gpucospa: file(relativePath: { eq: "cover/gpu/gpu-cospa.png"}) { ...eyecatchImg },
    cpubottleneck: file(relativePath: { eq: "cover/gpu/cpubottleneck.png"}) { ...eyecatchImg },
    intelarc: file(relativePath: { eq: "cover/gpu/intel-arc.png"}) { ...eyecatchImg },
    defaultthumb: file(relativePath: { eq: "cover/defaultthumb.png"}) { ...eyecatchImg },
  }
`
